import { HeartIcon } from "@heroicons/react/outline"
import { MailIcon, PhoneIcon } from "@heroicons/react/solid"
import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { FacebookIcon } from "./icons"
import {
  ContactLink,
  MeetTeamLink,
  NewPatientsLink,
  ServicesLink,
  TechnologyLink,
} from "./links"
import WechatButton from "./wechat-button"

const Footer = () => {
  const { tel, email, facebook } = useSiteMetadata()
  return (
    <footer className="p-10 footer bg-brand-primary text-base-content footer-center">
      <div className="grid grid-flow-col gap-4">
        <MeetTeamLink
          title="Meet Us"
          className="link text-zinc-300 link-hover"
        />
        <ServicesLink className="link text-zinc-300 link-hover" />
        <TechnologyLink className="link text-zinc-300 link-hover" />
        <NewPatientsLink
          title="New Patients"
          className="link text-zinc-300 link-hover"
        />
        <ContactLink
          title="Contact Us"
          className="link text-zinc-300 link-hover"
        />
      </div>
      <div>
        <div className="grid grid-flow-col gap-4">
          <a
            href={"tel:" + tel}
            className="link text-zinc-300 link-hover"
            aria-label="Phone number of business"
          >
            <PhoneIcon className="w-6 h-6" />
          </a>
          <a
            href={"mailto:" + email}
            className="link text-zinc-300 link-hover"
            aria-label="Email of business"
          >
            <MailIcon className="w-6 h-6" />
          </a>
          <a
            href={facebook}
            className="link text-zinc-300 link-hover"
            aria-label="Facebook page of business"
          >
            <FacebookIcon className="w-6 h-6" />
          </a>
          <WechatButton className="w-6 h-6" />
        </div>
      </div>
      <div>
        <p className="text-zinc-300">
          This site is protected by reCAPTCHA and the Google{" "}
          <a
            className="link text-zinc-300 link-hover"
            href="https://policies.google.com/privacy"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            className="link text-zinc-300 link-hover"
            href="https://policies.google.com/terms"
          >
            Terms of Service
          </a>{" "}
          apply.
        </p>
        <p className="text-zinc-300">
          Copyright © {new Date().getFullYear()}, All right reserved by Nguyen
          Orthodontics Dental Corporation.
        </p>
        <p className="text-zinc-300">
          Designed with{" "}
          <HeartIcon className="inline w-4 h-4" viewBox="0 2 24 24" /> by RBits.
        </p>
      </div>
    </footer>
  )
}

export default Footer
