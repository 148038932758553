import React from "react"
import PropTypes from "prop-types"

import Header from "../components/header"
import Footer from "../components/footer"

const HomeLayout = ({ children }) => {
  return (
    <>
      <div className="w-full min-h-screen flex flex-col">
        <Header />
        <main className="flex-1">{children}</main>
        <Footer />
      </div>
    </>
  )
}

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HomeLayout
