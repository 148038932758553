import React from "react"
import PropTypes from "prop-types"
import ContentLayout from "./content-layout"
import HomeLayout from "./home-layout"

const Layout = ({ children, pageContext }) => {
  if (pageContext.layout === "home") {
    return <HomeLayout>{children}</HomeLayout>
  }
  return <ContentLayout>{children}</ContentLayout>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
