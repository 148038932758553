exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-orthodontist-js": () => import("./../../../src/pages/about/orthodontist.js" /* webpackChunkName: "component---src-pages-about-orthodontist-js" */),
  "component---src-pages-about-team-js": () => import("./../../../src/pages/about/team.js" /* webpackChunkName: "component---src-pages-about-team-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-patients-js": () => import("./../../../src/pages/new-patients.js" /* webpackChunkName: "component---src-pages-new-patients-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-technology-covid-safety-js": () => import("./../../../src/pages/technology/covid-safety.js" /* webpackChunkName: "component---src-pages-technology-covid-safety-js" */),
  "component---src-pages-technology-index-js": () => import("./../../../src/pages/technology/index.js" /* webpackChunkName: "component---src-pages-technology-index-js" */)
}

