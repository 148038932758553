import React from "react"
import PropTypes from "prop-types"

import Header from "../components/header"
import Footer from "../components/footer"

const ContentLayout = ({ children }) => {
  return (
    <>
      <div className="w-full min-h-screen flex flex-col">
        <Header />
        <main className="flex-1 p-8 xl:px-80">{children}</main>
        <Footer />
      </div>
    </>
  )
}

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContentLayout
