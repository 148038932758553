import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

export const HomeLink = ({ children, title, ...props }) => (
  <Link to="/" {...props}>
    {(children || title) ?? "Home"}
  </Link>
)

HomeLink.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export const MeetTeamLink = ({ children, title, ...props }) => (
  <Link to="/about/team" {...props}>
    {(children || title) ?? "Meet our team"}
  </Link>
)

MeetTeamLink.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export const MeetDoctorLink = ({ children, title, ...props }) => (
  <Link to="/about/orthodontist" {...props}>
    {(children || title) ?? "Meet our orthodontist"}
  </Link>
)

MeetDoctorLink.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export const ServicesLink = ({ children, title, ...props }) => (
  <Link to="/services" {...props}>
    {(children || title) ?? "Services"}
  </Link>
)

ServicesLink.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export const TechnologyLink = ({ children, title, ...props }) => (
  <Link to="/technology" {...props}>
    {(children || title) ?? "Technology"}
  </Link>
)

TechnologyLink.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export const CovidSafetyLink = ({ children, title, ...props }) => (
  <Link to="/technology/covid-safety" {...props}>
    {(children || title) ?? "COVID-19 safety"}
  </Link>
)

CovidSafetyLink.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export const ContactLink = ({ children, title, ...props }) => (
  <Link to="/contact" {...props}>
    {(children || title) ?? "Contact us"}
  </Link>
)

ContactLink.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export const NewPatientsLink = ({ children, title, ...props }) => (
  <Link to="/new-patients" {...props}>
    {(children || title) ?? "New patients"}
  </Link>
)

NewPatientsLink.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}
