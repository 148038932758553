import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import Sidebar from "./sidebar"
import { ContactLink, HomeLink } from "./links"
import { StaticImage } from "gatsby-plugin-image"
import WechatButton from "./wechat-button"
import { MailIcon, PhoneIcon } from "@heroicons/react/solid"
import { CalendarIcon } from "@heroicons/react/outline"
import { FacebookIcon } from "./icons"

const Header = () => {
  const { title, tel, email, facebook } = useSiteMetadata()
  const siteTitles = title.split(" ")
  const siteTitleText =
    siteTitles.length === 2 ? (
      <div className="ml-1 md:ml-4">
        <span className="text-brand-primary font-bold text-2xl md:text-4xl">
          {siteTitles[0]}
        </span>{" "}
        <span className="text-brand-secondary font-bold text-2xl md:text-4xl">
          {siteTitles[1]}
        </span>
      </div>
    ) : (
      title
    )

  return (
    <>
      <div className="flex items-center h-9 bg-brand-primary w-full">
        <div className="grow md:flex-1 px-2 mx-2 flex space-x-2 justify-center md:justify-start">
          <a
            href={"tel:" + tel}
            className="link link-hover flex space-x-2 text-zinc-300"
            aria-label="Phone number of business"
          >
            <PhoneIcon className="w-5 h-5" />
            <span className="text-sm font-bold">{tel}</span>
          </a>
        </div>
        <div className="flex-none px-2 mx-2 hidden md:flex space-x-2 items-center">
          <a
            href={"mailto:" + email}
            className="link text-zinc-300 link-hover"
            aria-label="Email of business"
          >
            <MailIcon className="w-5 h-5" />
          </a>
          <a
            href={facebook}
            className="link text-zinc-300 link-hover"
            aria-label="Facebook page of business"
          >
            <FacebookIcon className="w-5 h-5" />
          </a>
          <WechatButton className="w-5 h-5" />
        </div>
        <div className="flex-none px-2 h-full hidden md:flex items-center">
          <ContactLink className="text-zinc-300 link-hover flex items-center space-x-2 uppercase font-bold">
            <CalendarIcon className="w-5 h-5" />
            <span>Request Appointment</span>
          </ContactLink>
        </div>
      </div>
      <header className="flex navbar top-0 sticky bg-base-300 z-10 w-full h-16">
        <div className="flex-1 px-1 sm:px-2 sm:mx-2">
          <HomeLink className="flex items-center">
            <StaticImage
              placeholder="TRACED_SVG"
              className="w-[30px] h-[30px] md:w-[42px] md:h-[42px]"
              src="../images/logo.png"
              alt={"Logo of " + title}
            />
            {siteTitleText}
          </HomeLink>
        </div>
        <Sidebar classNames="bg-brand-primary text-zinc-300" />
      </header>
    </>
  )
}

export default Header
