import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock"
import {
  ContactLink,
  CovidSafetyLink,
  HomeLink,
  MeetDoctorLink,
  MeetTeamLink,
  NewPatientsLink,
  ServicesLink,
  TechnologyLink,
} from "./links"
import { MenuIcon, MoonIcon, SunIcon, XIcon } from "@heroicons/react/outline"

const Sidebar = ({ classNames }) => {
  const [show, setShow] = useState(false)
  const theme = typeof window !== "undefined" && localStorage.getItem("theme")
  const [dark, setDark] = useState(
    (theme && theme.indexOf("dark") > -1) || false
  )
  const lightTheme = "corporate"

  useEffect(() => {
    if (dark) {
      document.documentElement.setAttribute("data-theme", "dark")
      document.documentElement.classList.add("dark")
      localStorage.setItem("theme", "dark")
    } else {
      document.documentElement.setAttribute("data-theme", lightTheme)
      document.documentElement.classList.remove("dark")
      localStorage.setItem("theme", lightTheme)
    }
    setShow(false)
  }, [dark])

  const menuRef = useRef()

  useEffect(() => {
    if (typeof window === "undefined") return

    if (show) {
      disableBodyScroll(menuRef.current)
    } else {
      enableBodyScroll(menuRef.current)
    }

    return function cleanup() {
      clearAllBodyScrollLocks()
    }
  }, [show])

  return (
    <>
      {!show && (
        <button aria-label="Menu button">
          <MenuIcon onClick={() => setShow(!show)} className="w-12 h-12" />
        </button>
      )}
      <div
        className={`flex flex-col fixed top-0 right-0 w-full md:w-1/4 md:min-w-fit h-full space-y-6 ease-in-out duration-300 ${classNames} ${
          show ? "translate-x-0 " : "translate-x-full"
        }`}
      >
        <button
          className="fixed top-6 right-6"
          aria-label="Close menu button"
          onClick={() => setShow(!show)}
        >
          <XIcon className="w-12 h-12" />
        </button>
        <div
          ref={menuRef}
          className="overflow-y-auto w-full pl-6 pr-20"
          data-theme="dark"
        >
          <ul className="menu space-y-2 uppercase">
            <li>
              <HomeLink
                onClick={() => setShow(!show)}
                activeClassName="bg-brand-secondary"
              />
            </li>
            <li>
              <MeetTeamLink
                onClick={() => setShow(!show)}
                activeClassName="bg-brand-secondary"
              />
            </li>
            <ul className="space-y-2">
              <li>
                <MeetDoctorLink
                  onClick={() => setShow(!show)}
                  activeClassName="bg-brand-secondary"
                />
              </li>
            </ul>
            <li>
              <ServicesLink
                onClick={() => setShow(!show)}
                activeClassName="bg-brand-secondary"
              />
            </li>
            <li>
              <TechnologyLink
                onClick={() => setShow(!show)}
                activeClassName="bg-brand-secondary"
              />
            </li>
            <ul className="space-y-2">
              <li>
                <CovidSafetyLink
                  onClick={() => setShow(!show)}
                  activeClassName="bg-brand-secondary"
                />
              </li>
            </ul>
            <li>
              <NewPatientsLink
                onClick={() => setShow(!show)}
                activeClassName="bg-brand-secondary"
              />
            </li>
            <li>
              <a
                href="/nguyen-orthodontics_referral-form.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Referral Form
              </a>
            </li>
            <li>
              <ContactLink
                onClick={() => setShow(!show)}
                activeClassName="bg-brand-secondary"
              />
            </li>
          </ul>
        </div>
        <div className="flex space-x-2 sm:space-x-1 !mb-5">
          <SunIcon className="w-6 h-6" />
          <input
            type="checkbox"
            checked={dark}
            className="toggle toggle-md"
            aria-label="Toggle this input to toggle between light and dark mode"
            onChange={() => setDark(!dark)}
          />
          <MoonIcon className="w-6 h-6" />
        </div>
      </div>
    </>
  )
}

Sidebar.propTypes = {
  classNames: PropTypes.string,
}

Sidebar.defaultProps = {
  classNames: "bg-brand-primary",
}

export default Sidebar
