import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import Modal from "./modal"
import { WechatIcon } from "./icons"

const WechatButton = ({ className }) => {
  const modalRef = useRef(null)
  const handleWechatClick = () => {
    modalRef.current.setOpen(true)
  }

  return (
    <>
      <button
        onClick={handleWechatClick}
        className="link text-zinc-300 link-hover"
        aria-label="Button to open WeChat QR code"
      >
        <WechatIcon className={className} />
      </button>
      <Modal ref={modalRef} title="Scan this QR code to add us">
        <StaticImage
          placeholder="TRACED_SVG"
          width={240}
          height={240}
          src="../images/wechat.jpg"
          alt="QR code to add this user to wechat"
        />
      </Modal>
    </>
  )
}

export default WechatButton

WechatButton.propTypes = {
  className: PropTypes.string,
}
